<template>
  <div class="mx-1">

    <!-- BREADCRUMB -->
<!--    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CallingCards'">-->
<!--    </breadcrumb-base>-->

    <div class="px-2">

      <div class="flex-row flex justify-end" style="place-items: center;">
<!--        <vs-checkbox class="pr-3" v-model="favCardsToggle">{{ $t('Favourites') }}</vs-checkbox>-->
        <a-z-pagination v-model="selectedAlphaCurrentX"
                        @clear="clearSelectedAlpha"
                        v-on:change="pageChanged"></a-z-pagination>
      </div>

      <!--    Error List State-->
      <transition name="fade" v-if="favCardsToggle">
        <div class="py-6 flex w-full bg-img" v-if="errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <!--          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"-->
            <!--               class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="w-full">
          <div class="flex" style="place-items: center; white-space: nowrap;">
            <vs-input class="vs-col w-full z-1" icon-pack="feather" icon="icon-search"
                      @input="selectedAlphaCurrentX = ''"
                      :placeholder="$t('Search')" v-model="searchText"/>
          </div>

          <template v-if="favCardsToggle">
            <div class="vs-row px-5 w-full pr-8 flex justify-between py-2" style="place-items: center">
              <h3 class=" py-2"><strong>{{ $t('Favourites') }}</strong></h3>
              <div class="vs-col px-4" v-if="configQuantity > 0">

                <div class="py-2 bg-white text-sm shadow-md rounded vx-row">
                  <div class="vs-col flex px-2" v-if="activeUserInfo.mask_seller_discount != 1" style="place-items: center;">
                    <p class="font-bold">{{ $t('BuyingPrice') }} <span
                      style="font-weight: normal;">{{ buyingPrice| germanNumberFormat }}</span></p>
                  </div>
                  <div class="vs-col flex px-2" v-if="activeUserInfo.mask_seller_discount != 1" style="place-items: center;">
                    <p class="font-bold">{{ $t('BuyingDiscount') }} <span style="font-weight: normal;">{{ discount }} %</span>
                    </p>
                  </div>
                  <div class="vs-col flex px-2" style="place-items: center;">
                    <p class="font-bold">{{ $t('SellingPrice') }} <span style="font-weight: normal;">{{
                        totalBill | germanNumberFormat
                      }}</span>
                    </p>
                  </div>
                  <div class="vs-col flex px-2" v-if="activeUserInfo.mask_seller_discount != 1" style="place-items: center;">
                    <p class="font-bold">{{ $t('Profit') }} <span style="font-weight: normal;">{{ profit | germanNumberFormat }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="activeUserInfo.internal_role == 0">
                <vs-button v-if="editFav"
                           class="ml-4"
                           @click="editFav = false" color="danger">
                  <strong>{{ $t('Cancel') }}</strong>
                </vs-button>
                <vs-button v-if="editFav"
                           @click="updateFavSequence()"
                           class="ml-4">
                  <strong>{{ $t('Update') }}</strong>
                </vs-button>
                <vs-button v-if="!editFav"
                           class="ml-4"
                           @click="editFav = true">
                  <strong>{{ $t('EditFavourites') }}</strong>
                </vs-button>
              </div>
            </div>
            <transition name="fade">
              <div class=" flex w-full bg-img" v-if="favCallingCards.length === 0">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">

                  <h3 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h3>
                </div>
              </div>
            </transition>
            <div class="flex flex-row flex-wrap w-full">
              <template v-for="(card, index) in favCallingCards">
                <div class=" w-full md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/4 vs-col">
                  <vx-card :draggable="editFav" class="mb-1 mt-2 mr-4 fav-custom-card"
                           v-bind:key="index"
                           @drop="moveCard($event, index)"
                           @dragover.prevent
                           @dragenter.prevent
                           @dragstart="pickUpCard($event, index)"
                           style="width: auto"
                  >
                    <div class="vx-row text-center px-2" style="place-items: center;margin: auto;">
                      <div class="flex flex-col py-2 relative">
                        <img class="w-24 h-24 no-drag" :src="'https://e-aufladen.de' + card.product_image"
                             :alt="card.product_name"
                             draggable="false"
                             @dragstart.prevent
                             style="object-fit: contain;"/>
                        <div class="tooltip" v-if="card.product_configurations[0].info">
                          <h1 class="item-card-text custom-card-value ml-4">{{ card.product_configurations[0].value | germanNumberFormat }}</h1>
                          <span class="tooltiptext tooltip-top">{{ card.product_configurations[0].info }}</span>
                        </div>
                        <h1 v-else class="item-card-text custom-card-value ml-2"
                            style="margin: 6px 0px; color: black; font-size: 12px !important;">
                          {{ card.product_configurations[0].value | germanNumberFormat }}</h1>
                      </div>
                      <!--                          <vs-spacer></vs-spacer>-->
                      <vs-button @click="removeQuantity(card.product_configurations[0].id)"
                                 class="mx-1 custom-button small-button" size="normal"
                                 color="danger"
                                 type="filled"
                                 icon-pack="feather" icon="icon-minus"></vs-button>
                      <div class="flix-input-holder">
                        <div class="">
                          <input v-model="selectedConfig == card.product_configurations[0].id ? configQuantity : 0"
                                 :id="'config_' + card.product_configurations[0].id"
                                 type="text"
                                 placeholder="0" class="flix-input-field custom-input"
                          >
                        </div>
                      </div>
                      <vs-button @click="addQuantity(card.product_configurations[0])"
                                 class="mx-1 custom-button small-button" size="normal"
                                 color="success" type="filled"
                                 icon-pack="feather" icon="icon-plus"></vs-button>

                      <vs-button :disabled="selectedConfig != card.product_configurations[0].id"
                                 color="secondary"
                                 size="normal"
                                 style="padding: .5rem 2rem;"
                                 class="ml-2 px-4 " @click="purchaseCard()">
                        <strong>{{ $t('Buy') }}</strong>
                      </vs-button>
                      <vs-button
                        class="fav-button mx-1 absolute top-0 right-0"
                        radius color="danger"
                        :key="card.product_configurations[0].id"
                        type="flat"
                        :icon="card.product_configurations[0].favorite == 1 ? 'favorite' : 'favorite_border'"
                        @click="removeAddFromFav(card.product_configurations[0].favorite == 1 ? 0 : 1, card.product_configurations[0])"
                        size="large"></vs-button>
                    </div>

                  </vx-card>
                </div>
              </template>
            </div>


          </template>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="callingCards.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <div class="w-full py-3 flex flex-row flex-wrap mb-12">

            <div
              class="w-full px-4 py-4 sm:w-1/2 md:w-1/4 lg:w-1/6 xl:w-1/6 2xl:w-1/8 text-center vs-col"
              v-bind:key="index" v-for="(card, index) in callingCards">
              <div class="">
                <div class="bg-white rounded-lg cursor-pointer py-2"
                         @click="openConfigsPopUp(card)">
                  <img class="card-image" :src="'https://pjtelesoftgmbh.de' + card.product_image"
                       :alt="card.product_name"/>
                </div>
<!--                <div >-->
<!--                  <p class="text-lg py-1 font-bold">{{ card.product_name }}</p>-->
<!--                </div>-->
              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>

    <div>
      <bottom-modal v-if="showConfigsActive" @close="closedShowConfigs">
        <template slot="body">
          <div class="p-3 flex flex-row flex-centered">
            <div class="flex flex-col justify-center col-mx-auto  w-full">
              <div class="vx-col text-center mb-4" style="display: flex; justify-content: center">
                <img class="modal-card-image bg-white rounded-lg" :src="'https://e-aufladen.de' + selectedProduct.product_image"
                     :alt="selectedProduct.product_name"/>
              </div>
              <div class="flex flex-row flex-wrap w-full ">
                <div
                  class="md:w-1/3 my-2 flex flex-wrap px-2"
                  v-for="(config, index) in selectedProduct.product_configurations">
                  <div
                    class="bg-white rounded-lg shadow-lg w-full flex flex-row justify-between items-center text-center py-4 px-1"
                    >
                    <div class="tooltip" v-if="config.info">
                      <h1 class="mx-3 item-card-text ">{{ config.value | germanNumberFormat }}</h1>
                      <span class="tooltiptext tooltip-top">{{ config.info }}</span>
                    </div>
                    <h1 v-else class="mx-3 item-card-text">{{ config.value | germanNumberFormat }}</h1>

                    <div class="flex flex-row items-center">
                      <vs-button @click="removeQuantity(config.id)" class="mx-2 " size="small" color="danger"
                                 type="filled"
                                 icon-pack="feather" icon="icon-minus"></vs-button>
                      <div class="flix-input-holder mx-4">
                        <div class="">
                          <input v-if="selectedConfig == config.id"
                                 v-model="configQuantity"
                                 :id="'config_' + config.id"
                                 type="text"
                                 @input="configInputChange($event,config)"
                                 placeholder="0" class="flix-input-field"
                          >
                          <input v-else
                                 :id="'config_' + config.id"
                                 type="text"
                                 @input="configInputChange($event,config)"
                                 placeholder="0" class="flix-input-field"
                          >
                        </div>
                      </div>
                      <!--                      <h4 class="item-card-amount">{{ selectedConfig == config.id ? configQuantity : 0 }}</h4>-->
                      <vs-button @click="addQuantity(config)" class="mx-2" size="small" color="success" type="filled"
                                 icon-pack="feather" icon="icon-plus"></vs-button>

<!--                      <vs-button-->
<!--                        class="fav-button"-->
<!--                        radius color="danger"-->
<!--                        type="flat"-->
<!--                        :icon="config.favorite == 1 ? 'favorite' : 'favorite_border'"-->
<!--                        @click="removeAddFromFav(config.favorite == 1 ? 0 : 1, config)">-->
<!--                      </vs-button>-->
                    </div>
                  </div>
                </div>

              </div>
              <div class="p-8"></div>

              <div class="flex justify-evenly w-full card-billing-text">
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{
                      buyingPrice | germanNumberFormat
                    }}</span></p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingDiscount') }} <span class="card-billing-info">{{ discount }} %</span>
                  </p>
                </div>
                <div class="vx-row">
                  <p>{{ $t('SellingPrice') }} <span class="card-billing-info">{{
                      totalBill | germanNumberFormat
                    }}</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>
                </div>
              </div>

              <div class="p-2"></div>
            </div>
            <div class="flex-col flex justify-center w-48 ml-5">
              <vs-button size="large" class="my-2 bg-template4-success" color="success" @click="purchaseCard()">
                <strong>{{ $t('Buy') }}</strong>
              </vs-button>
              <vs-button size="large" class="my-2" color="danger" @click="closedShowConfigs()">
                <strong>{{
                    $t('Close')
                  }}</strong>
              </vs-button>

            </div>
          </div>
        </template>
      </bottom-modal>
<!--      <vx-modal v-if="showConfigsActive" @close="closedShowConfigs" component-class="modal-container-7">-->
<!--        <template slot="header"></template>-->

<!--        <template slot="footer"></template>-->
<!--      </vx-modal>-->

    </div>
    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <receipt-print
            v-on:close="closeModal()"
            ref="printCard"
            :print-report-type="0" :purchase-data="purchaseData"></receipt-print>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'
import AZPagination from "@/components/template3/AZPagination";
import BottomModal from "@/components/template3/BottomModal";

export default {
  name: 'CallingCards',
  components: {
    BottomModal,
    AZPagination,
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      showConfigsActive: false,
      selectedProduct: null,
      errorFetching: false,
      configQuantity: 0,
      configPrice: 0,
      selectedConfig: null,
      selectedFavConfig: null,
      totalBill: 0,
      buyingPrice: 0,
      discount: 0,
      profit: 0,
      filterAccountStatus: [],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: '',
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Cash Cards', i18n: 'CallingCards', active: true},
      ],
      purchaseData: null,
      showPurchaseDataModal: false,
      favCardsToggle: false,
      editFav: false,
      doingTransaction: false,
    }
  },
  computed: {
    callingCards() {
      return this.$store.state.sellerCallingCards.callingCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()))
    },
    favCallingCards() {
      return this.$store.state.sellerCallingCards.favCallingCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()))
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    updateFavSequence() {
      const favs = []
      this.favCallingCards.forEach((d, index) => {
        favs.push({
          pad_id: d.product_configurations[0].pad_id,
          favorite: 1,
          sequence: index,
        })
      })
      const data = {
        product_type: 'cash card',
        favorite_update: JSON.stringify(favs),
      }
      this.$vs.loading()
      this.$store.dispatch('sellerCallingCards/fetchCallingCards', data)
        .then((data) => {
          this.$vs.loading.close()
          this.editFav = false
          this.fetchFavCallingCards()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    moveCard(event, index) {
      const oldIndex = event.dataTransfer.getData('index')
      this.$store.commit('sellerCallingCards/ARRANGE_FAV_CASH_CARDS', {oldIndex: oldIndex, newIndex: index})
    },
    pickUpCard(event, index) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('index', index)
    },
    closeModal() {
      this.showPurchaseDataModal = false
      this.closedShowConfigs()
      this.fetchAllCallingCards()
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    calculateBill() {
      this.totalBill = this.configQuantity * this.configPrice
      const p = ((this.totalBill) * (this.discount / 100))
      this.buyingPrice = (this.totalBill - p).toFixed(2);
      this.profit = this.totalBill - this.buyingPrice;
    },
    configInputChange(event, config) {
      if (this.selectedConfig == config.id) {
        this.selectedConfig = config.id
      } else {
        this.discount = config.discount
        this.configPrice = config.value
        this.selectedConfig = config.id
        this.configQuantity = Number(event.data)
      }
      this.calculateBill()
    },
    addQuantity(config) {
      if (this.selectedConfig == config.id) {
        this.configQuantity = Number(this.configQuantity) + 1
      } else {
        this.discount = config.discount
        this.configPrice = config.value
        this.selectedConfig = config.id
        this.configQuantity = 1
      }
      this.calculateBill()
    },
    removeQuantity(id) {
      if (this.selectedConfig == id) {
        if (this.configQuantity >= 1) this.configQuantity = Number(this.configQuantity) - 1
      }
      this.calculateBill()
    },
    openConfigsPopUp(p) {
      this.selectedProduct = p
      this.showConfigsActive = true

      if (this.selectedProduct.product_configurations.length === 1) {
        let id = this.selectedProduct.product_configurations[0].id
        setTimeout(() => {
          document.getElementById(`config_${id}`).focus()
          document.getElementById(`config_${id}`).select()
        }, 500)
      }
    },
    closedShowConfigs() {
      this.selectedProduct = null
      this.selectedConfig = null
      this.configQuantity = 0
      this.showConfigsActive = false
      this.totalBill = 0
      this.discount = 0
      this.profit = 0
    },
    removeAddFromFav(what, config) {

      const data = {
        product_type: 'cash card',
        favorite_update: JSON.stringify([
          {
            pad_id: config.pad_id,
            favorite: what,
          },
        ]),
      }
      this.$vs.loading()
      this.$store.dispatch('sellerCallingCards/fetchCallingCards', data)
        .then((data) => {
          this.$vs.loading.close()
          if (this.selectedProduct) {
            this.callingCards.forEach((card) => {
              if (this.selectedProduct.product_id === card.product_id) {
                this.selectedProduct = card
              }
            })
          }
          this.fetchFavCallingCards()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    purchaseCard() {
      if(this.doingTransaction) {
        return;
      }
      if (!this.selectedConfig || this.configQuantity <= 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select atleast 1 quantity',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      if (this.configQuantity > 50) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Maximum of 50 quantity',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const data = {
        product_configuration_id: this.selectedConfig,
        quantity: this.configQuantity,
      }
      this.doingTransaction = true;
      this.showConfigsActive = false
      this.$vs.loading()
      this.$store.dispatch('sellerCallingCards/purchaseCard', data)
        .then((data) => {
          this.doingTransaction = false;
          this.$vs.loading.close()
          this.purchaseData = data
          this.showPurchaseDataModal = true
          this.closedShowConfigs()
          this.checkPrintCardPop()
        })
        .catch((error) => {
          this.doingTransaction = false;
          this.closedShowConfigs()
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchFavCallingCards() {
      this.$store.dispatch('sellerCallingCards/fetchFavCallingCards', {})
        .then((data) => {
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchAllCallingCards() {
      this.$vs.loading()
      this.$store.dispatch('sellerCallingCards/fetchCallingCards', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  created() {
    if(this.callingCards.length <= 0) {
      this.fetchAllCallingCards()
    }
    if(this.favCallingCards.length <= 0) {
      this.fetchFavCallingCards()
    }
  },
  mounted() {
    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.mergeReceipts = localStorage.getItem('merge_receipts') == 'true'
  },
}
</script>

<style scoped>
.modal-container {
  border-radius: 30px !important;
}

.card-image {
  height: 80px;
  object-fit: contain;
  margin: auto;
  width: 100% !important;
}

.modal-card-image {
  max-height: 120px;
}

.item-card-text {
  font-size: 28px;
  font-weight: bolder;
}
.custom-card-value {
  font-size: 24px !important;
}

.card-text {
  font-size: 20px;
  font-weight: bolder;
}

.item-card-amount {
  font-size: 28px;
  padding: 0 10px;
}

.card-billing-text {
  font-size: 24px;
  font-weight: bold !important;
}

.card-billing-info {
  font-weight: normal !important;
}

.flix-input-holder {
  width: 42px;
  padding: 0;
  margin: 0;
}

.flix-input-field {
  height: 32px;
  border: 1px solid #c8c8c8;
  text-align: center;
  width: 100%;
  text-indent: 0;
  border-radius: 5px;
  font-size: 24px;
}

.hover-card-item:hover {
  background: #776cf0;
  color: white !important;
}

.custom-button .vs-icon {
  font-size: 2rem !important;
  font-weight: bolder;
}

.custom-card {
  border-radius: .5rem .5rem 0 0;
  border: 1px solid #62626285;
  border-bottom: none;
}

.fav-card-item {
  margin-right: 0 !important;
}

</style>
